// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import propellerImg from "./assets/propeller.png"
import turbineImg from "./assets/turbine.png"
import parcelImg from "./assets/parcel.png"
import dog1Img from "./assets/dog-1.png"
import dog1AltImg from "./assets/dog-1-alt.png"
import dog2Img from "./assets/dog-2.png"
import doorImg from "./assets/door.png"
import boxImg from "./assets/box.png"

// Sprites
import musicImg from "./assets/sprites/music.png"
import droneImg from "./assets/sprites/drone.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 2,
  preHeading: "Living Locally",
  heading: "It is here that we play and share and give.",
  content:
    "Where we saunter through our streets with a smile. It’s where we deemed it daft to have to drive to live, so we brought life to us. Here, we nip around the corner to the cul-de-sac café, or cut down the close to the chemist. It’s where we turned our sheds to shops and garages are for get-togethers. This is where we re-turf the roadsides and trade tarmac for our space back. Here we say yes to ball games and gala day gatherings. This must be the place where community grows from the heart, not the centre.",
  slug: "living-locally",
  nextSlug: "dear-green-places",
}

const LivingLocally = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 88 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 1200 },
    }),
    turbine1 = useRef({
      parallax: 0.2,
      src: turbineImg,
      el: null,
      offset: { x: 365, y: 350, r: 0 },
      displaySize: { w: 380, h: 380 },
      rotationPoint: { x: 190, y: 190 },
    }),
    turbine2 = useRef({
      parallax: 0.2,
      src: turbineImg,
      el: null,
      offset: { x: 2445, y: 138, r: 200 },
      displaySize: { w: 200, h: 200 },
      rotationPoint: { x: 100, y: 100 },
    }),
    music = useRef({
      parallax: 0.5,
      src: musicImg,
      el: null,
      offset: { x: 1830, y: 360 },
      size: { w: 60, h: 130 },
      displaySize: { w: 75, h: 162 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 7,
      loop: true,
      paused: true,
    }),
    parcel = useRef({
      parallax: 0.8,
      src: parcelImg,
      el: null,
      offset: { x: -899, y: -487, r: 0 },
      rotationPoint: { x: 91.5, y: -222 },
    }),
    drone = useRef({
      parallax: 0.8,
      src: droneImg,
      el: null,
      offset: { x: -1000, y: -700, r: 0 },
      size: { w: 390, h: 220 },
      rotationPoint: { x: 195, y: 5 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    propeller1 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 1728, y: 1279, r: 10 },
      displaySize: { w: 52, h: 52 },
      rotationPoint: { x: 26, y: 26 },
    }),
    propeller2 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 1786, y: 1306, r: 90 },
      displaySize: { w: 52, h: 52 },
      rotationPoint: { x: 26, y: 26 },
    }),
    propeller3 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 2816, y: 1476, r: 210 },
      displaySize: { w: 52, h: 52 },
      rotationPoint: { x: 26, y: 26 },
    }),
    propeller4 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 2874, y: 1503, r: 80 },
      displaySize: { w: 52, h: 52 },
      rotationPoint: { x: 26, y: 26 },
    }),
    propeller5 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 3622, y: 850, r: 40 },
      rotationPoint: { x: 65, y: 65 },
    }),
    propeller6 = useRef({
      parallax: 0.5,
      src: propellerImg,
      el: null,
      offset: { x: 3622, y: 1012, r: 0 },
      rotationPoint: { x: 65, y: 65 },
    }),
    dog1 = useRef({
      parallax: 1,
      src: dog1Img,
      el: null,
      offset: { x: 453, y: 1644, r: 0 },
    }),
    dog1alt = useRef({
      parallax: 1,
      src: dog1AltImg,
      el: null,
      offset: { x: 453, y: 1644, r: 0 },
      hidden: true,
    }),
    box = useRef({
      parallax: 1,
      src: boxImg,
      el: null,
      offset: { x: 448, y: 1830, r: 0 },
    }),
    dog2 = useRef({
      parallax: 1,
      src: dog2Img,
      el: null,
      offset: { x: 1582, y: 1476, r: 0 },
    }),
    door = useRef({
      parallax: 1,
      src: doorImg,
      el: null,
      offset: { x: 1548, y: 1490, r: 0 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    turbine1.current,
    turbine2.current,
    mg.current,
    propeller1.current,
    propeller2.current,
    propeller3.current,
    propeller4.current,
    propeller5.current,
    propeller6.current,
    music.current,
    parcel.current,
    drone.current,
    fg.current,
    dog1.current,
    dog1alt.current,
    box.current,
    dog2.current,
    door.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([music.current, drone.current])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 1870,
    y: 600,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    // Turbines
    turbine1.current.offset.r = turbine1.current.offset.r + 0.4
    turbine2.current.offset.r = turbine2.current.offset.r + 0.6

    // Propellers
    propeller1.current.offset.r = propeller1.current.offset.r + 1
    propeller2.current.offset.r = propeller2.current.offset.r + 1
    propeller3.current.offset.r = propeller3.current.offset.r + 1.5
    propeller4.current.offset.r = propeller4.current.offset.r + 1.5
    propeller5.current.offset.r = propeller5.current.offset.r + 2
    propeller6.current.offset.r = propeller6.current.offset.r + 2

    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const showBaguette = direction => {
    if (direction === "in") {
      dog1.current.hidden = true
      dog1alt.current.hidden = false
      gsap.to(dog2.current.offset, {
        y: 1376,
        duration: 1,
        ease: "power1.inOut",
      })
    } else {
      dog1.current.hidden = false
      dog1alt.current.hidden = true
      gsap.to(dog2.current.offset, {
        y: 1476,
        duration: 1,
        ease: "power1.inOut",
      })
    }
  }

  const showMusic = direction => {
    if (direction === "in") {
      music.current.loop = true
      music.current.currentSprite = 0
      music.current.paused = false
    } else {
      music.current.loop = false
    }
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.to(drone.current.offset, {
      y: 260,
      duration: 10,
      ease: "back.out(1.3)",
      delay: 1,
    })
    gsap.to(parcel.current.offset, {
      y: 473,
      duration: 10,
      ease: "back.out(1.3)",
      delay: 1,
    })
    gsap.to(drone.current.offset, {
      x: 1240,
      duration: 20,
      ease: "back.out(1.3)",
      delay: 1,
    })
    gsap.to(parcel.current.offset, {
      x: 1341,
      duration: 20,
      ease: "back.out(1.3)",
      delay: 1,
    })
    gsap.fromTo(
      drone.current.offset,
      { r: 5 },
      { r: -5, duration: 2, ease: "power2.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      parcel.current.offset,
      { r: 5 },
      { r: -5, duration: 2, ease: "power2.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      dog1alt.current.offset,
      { y: 1644 },
      { y: 1648, duration: 0.08, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showBaguette,
              x: 870,
              y: 1150,
              w: 1050,
              h: 670,
            },
            {
              event: showMusic,
              x: 1630,
              y: 350,
              w: 500,
              h: 400,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default LivingLocally
